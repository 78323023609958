/* ======================================
   Fonts
   ====================================== */
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700i');

/* ======================================
   Margin and Spacing
   ====================================== */
.space_x1 {
    padding: 1vw 0;
}

.space_x2 {
    padding: 2vw 0;
}

.space_x3 {
    padding: 3vw 0;
}

.space_x5 {
    padding: 5vw 0;
}

.space_x6 {
    padding: 6vw 0;
}

.space_x7 {
    padding: 7vw 0;
}

.space_x {
	@for $i from 1 through 7 {
		&#{$i}{
			padding: #{$i}vw 0;
		}
	}
}

@each $elm_p_m in padding, margin { 
	.#{$elm_p_m}_ {
		@for $i from 0 through 30 {
			&#{$i} {
				#{$elm_p_m}: #{$i}px;
			}
		}
	}
}

.p_ {
	@each $p_elm in top,left,right,bottom {
		@for $i from 0 through 50 {
			&#{$p_elm}_#{$i}{
				padding-#{$p_elm}: #{$i}px;
			}
		}
	}
}

.m_0_auto {
	margin: 0 auto;
}

.m_ {
	@each $p_elm in top,left,right,bottom {
		@for $i from 0 through 50 {
			&#{$p_elm}_#{$i} {
				margin-#{$p_elm}: #{$i}px!important;
			}
		}
	}
}

// Font Styles
.font_size_ {
	@for $i from 10 through 73 {
		&#{$i}{
			font-size: #{$i}px;
		}
	}
}

/* ======================================
   Colors
   ====================================== */
$blue: #5483b7;
$blue-light: #f7f9fc;

.color-blue-pad {
	color: $blue;
}

.color_blue {
	color: $blue;
}

.c_white {
	color: #fff;
}

.bg-blue-pad {
	background-color: $blue;
}

.bg-blue-light {
	background-color: $blue-light;
}

.bg-green-transp {
	background-color: rgba(232, 247, 247, .9);
}

.bg_white {
	background-color: #fff;
}

.bg_cover {
	background-size: cover;
	background-position: center;
}

/* ======================================
   Base
   ====================================== */
* {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
   outline: none!important;
}

body {
	background: transparent url('../assets/site/img/bg.png') repeat scroll left top;
}

html, 
body {
    position: relative;
    min-height: 100vh;
    font-size: 16px; 
    font-family: 'PT Sans', sans-serif;
    color: #5d686f; 
    font-weight: 300;
    width: 100%;

    h1, h2, h3, h4, h5, h6 {
    	font-weight: 600;
    	line-height: 120%;
    }
}

input,
textarea {
	width: 100%;
	padding: 10px 20px;
	border: solid 2px #eee;

	&:focus {
		border-color: #ccc;
	}
}

input {
	height: 50px;
	line-height: 50px;
}

textarea {
	display: block;
}

span.error {
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #e67d7d;
}

.container {
	max-width: 1170px;
}

/* ======================================
   Header
   ====================================== */
.logo {
	min-width: 140px;
}

.menu {
	width: 100%;
	-webkit-transition: all 0.3s ease-out;  
	transition: all 0.3s ease-out;

	&.menu_mobile {
		display: none;
	}
	
	ul {
		list-style: none; 
		padding: 0; 
		margin: 0;

		li {
			display: table-cell;
			vertical-align: middle;
			width: 1%;
			position: relative;
			text-align: center;

			a {
				text-decoration: none;
				color: #5d686f;
				display: block;
				font-size: 15px;
				cursor: pointer;
				padding: 50px 0;
				font-weight: 600;
				letter-spacing: 0.5px;
				-webkit-transition: all 0.3s ease-out;  
		        transition: all 0.3s ease-out;

				&:hover,
				&:focus {
					background-color: $blue-light;
					color: $blue; 
				}
			}
		}

		li.active a {
			background-color: $blue-light;
			color: $blue; 
		}
	}
}

.toggle_menu {
	display: none;
	min-width: 85px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;

	span {
		display: block;
		position: relative;
		width: 100%;
		height: 4px;
		background-color: $blue;
		border-radius: 30px;
		margin: 15px 0;

		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			left: 0;
			height: 4px;
			background-color: $blue;
			border-radius: 30px;
			-webkit-transition: all 0.3s ease-out;  
		    transition: all 0.3s ease-out;
		}

		&:before {
			top: -8px;
		}

		&:after {
			bottom: -8px;
		}
	}

	&.active span {
		background-color: transparent;

		&:before,
		&:after {
			width: 80%;
			left: 5px;
		}
		
		&:before {
			top: 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		&:after {
			bottom: 0;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

/* ======================================
   Banners
   ====================================== */
.banner {
	min-height: 250px;
	text-shadow: 0 0 5px rgba(0,0,0,0.4);
}

.swiper-pagination-bullet-active {
	background: $blue;
}

.swiper-partners {

	.swiper-wrapper {
	    padding-bottom: 35px;

		.swiper-slide {
			position: relative;
			height: 150px;
			padding: 15px;

			img {
				max-height: 70px;
			    max-width: 120px;
			    position: relative;
			    left: 50%;
			    top: 50%;
			    -webkit-transform: translate(-50%, -50%);
			    -ms-transform: translate(-50%, -50%);
			    transform: translate(-50%, -50%);
			    -webkit-filter: grayscale(100%);
			    filter: grayscale(100%);
				-webkit-transition: all 0.2s ease-out;  
			    transition: all 0.2s ease-out;

			    &:hover {
				    -webkit-filter: grayscale(0%);
				    filter: grayscale(0%);
			    }
			}
		}
	}
}

.banner_donation {
	width: 100%; 
	text-align: center;
	background: transparent url('../assets/site/img/banner-doacao.jpg') no-repeat scroll center center;
	background-size: cover; 
}

.banner_collabore {
	background: transparent url('../assets/site/img/home-banner-doar.jpg') no-repeat scroll center center;
	background-size: cover; 
}

/* ======================================
   Buttons
   ====================================== */
.btn_custom {
	font-size: 15px;
	text-transform: uppercase;
	background-color: transparent;
	min-width: 200px;
	border-radius: 40px;
	display: inline-block;
	text-align: center;
	padding: 10px 0;
	font-weight: 600;
	text-decoration: none;
	border: solid 2px $blue;
	letter-spacing: 0.5px;
	-webkit-transition: all 0.3s ease-out;  
    transition: all 0.3s ease-out;

    &:hover,
    &:focus {
    	text-decoration: none;
		text-shadow: none;
    	box-shadow: 0 5px 10px rgba(0,0,0,0.3);
    	-webkit-transform: translate(0, -4px);
    	-ms-transform: translate(0, -4px);
    	transform: translate(0, -4px);
    }

	&.btn_blue {
		color: $blue;
		border-color: $blue;
	    
	    &:hover,
	    &:focus {
	    	color: #fff;
	    	background-color: $blue; 
	    }
	}

	&.btn_white {
		color: #fff;
		border-color: #fff;

	    &:hover,
	    &:focus {
	    	color: $blue;
	    	background-color: #fff; 
	    }
	}

	&.btn_green_solid {
		background-color: #79a135;
		border-color: #79a135;
		color: #fff;
		font-size: 12px;
		padding: 8px 0;
		min-width: 120px;

		&:hover,
		&:focus {
			color: #fff;
			background-color: #79a135;
		}
	}
}

.mask_hover {
    position: relative;
    display: block;

    &:after {
    	content: "";
    	display: block;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	width: 100%;
    	height: 100%;
    	border-radius: 100%;
    	background: rgba(84, 131, 183, 0.5);
    	visibility: hidden;
    	opacity: 0;
    	-webkit-transform: translate(-50%, -50%) scale(0);
    	-ms-transform: translate(-50%, -50%) scale(0);
    	transform: translate(-50%, -50%) scale(0);
		-webkit-transition: all 0.3s ease-out;  
        transition: all 0.3s ease-out;
    }

    &:hover:after {
    	border-radius: 10px;
    	visibility: visible;
    	opacity: 1;
    	-webkit-transform: translate(-50%, -50%) scale(1);
    	-ms-transform: translate(-50%, -50%) scale(1);
    	transform: translate(-50%, -50%) scale(1);
    }
 }

/* ======================================
   Home
   ====================================== */
.box_post {
    .img_post {
	    position: relative;
	    display: block;
	    width: 100%;
    	height: 200px;
	    background-size: cover;
	    border-radius: 10px;
	    background-position: center;
	 }

    h3 a {
    	color: #5d686f;
    	text-decoration: none; 

    	&:hover,
    	&:focus {
    		color: #5d686f;
    		text-decoration: none; 
    	}
    }

	.flag {
	    position: absolute;
	    left: -6px;
	    top: -8px;
	    z-index: 1;
	    overflow: hidden;
	    width: 90px;
	    height: 75px;
	    opacity: 0.97;

	    span {
		    display: block;
		    position: absolute;
		    top: 14px;
		    left: -14px;
		    font-size: 10px;
		    letter-spacing: 0.5px;
		    font-weight: 700;
		    color: #FFF;
		    text-transform: uppercase;
		    text-align: center;
		    line-height: 22px;
		    transform: rotate(-36deg);
		    -ms-transform: rotate(-36deg);
		    -webkit-transform: rotate(-36deg);
		    width: 100px;
		    box-shadow: 0 3px 10px -5px rgba(0,0,0,1);
		    background: #1e5799;
		    background: -mos-linear-gradient(#73baf5 0,#1e5799 100%);
		    background: -o-linear-gradient(#73baf5 0,#1e5799 100%);
		    background: -webkit-linear-gradient(#73baf5 0,#1e5799 100%);
		    background: linear-gradient(#73baf5 0,#1e5799 100%);

			&::after,
			&::before {
				content: "";
				position: absolute;
				top: 100%;
				z-index: -1;
				border-bottom: 3px solid transparent;
				border-top: 3px solid #1e5799;
			}

			&::before {
			    left: 0;
			    border-left: 3px solid #1e5799;
			    border-right: 3px solid transparent;
			}

			&::after {
			    right: 0;
			    border-left: 3px solid transparent;
			    border-right: 3px solid #1e5799;
			}
		}
	}
}

.contacts_home {
	margin: 0;
	padding: 0;

	li {
		display: block;
		position: relative;
		list-style: none;
		margin: 5px 0;
		padding: 20px 10px 0px 55px;

		i {
			position: absolute;
			top: calc(50% - 12px);
			left: 5px;
			width: 40px;
			height: 40px;
			color: #999;
			line-height: 36px;
			text-align: center;
			padding: 0 10px;
			border: solid 2px #eee;
			-webkit-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
		}

		a {
			color: #5d686f;
			text-decoration: none;
		}

		&:hover i {
			background-color: #eee;
			border-radius: 100%;
		}
	}
}

.form-newsletter button {
	color: #fff;
	background-color: $blue;
	border-radius: 0;
	line-height: 40px;
	width: 100%;
	display: block;
	font-weight: 600;
	font-size: 16px;
	
	&:hover,
	&:focus {
		color: #fff;
	}
}

/* ======================================
   Staff
   ====================================== */
.box-cooperated {
	margin-bottom: 30px; 
	
	.img-cooperated {
		border-radius: 50%;
		width: 110px;
		height: 110px;
		background-size: cover;
		background-position: center;
		margin: 0 auto; 
	}
}

/* ======================================
   Galeries
   ====================================== */
.gallery_thumb {
	display: block;
	text-decoration: none;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: inherit;
	}
}

/* ======================================
   How to help
   ====================================== */
.box-bank {
	border: solid 1px rgba(73, 131, 188, 0.2); 
	padding: 30px 40px;
    border-radius: 10px;
    margin-bottom: 50px;
    
    h4 {
    	font-size: 20px; 
    	margin-top: 0; 
    	margin-bottom: 2px;
    }
}

/* ======================================
   Contact
   ====================================== */
.form-page-contact {
	button.send {
		color: #fff;
	    background-color: #43a300;
	    border-color: #43a300;
	    border-radius: 0;
	    height: 45px;
	    font-weight: 600;
	    padding: 10px 20px;
	    width: 100%;
	    -webkit-transition: all 0.3s ease-out;
	    transition: all 0.3s ease-out;
	    
	    &:hover,
	    &:focus {
			background-color: transparent; 
			color: #43a300;
	    }
	}
}

.contacts-blocks {
	display: flex; 
	
	.block {
		width: 33.3333%; 
		float: left; 
		padding-top: 40px;
		padding-bottom: 50px;
		padding-left: 5px;
		padding-right: 5px;

		p {
			margin-bottom: 4px;
		}

		a {
			text-decoration: none; 
			color: #5d686f;
		}
	}

	.block-1, 
	.block-3 {
		background-color: #e8f0d9; 
		
		h4 {
			color: #6aa204; 
		}
	}

	.block-2 {
		background-color: #f7f9fc; 
		
		h4 {
			color: #6f9ec8; 
		}
	}

}

.maps {
	display: flex;
	align-items: center;
}

.col-50 {
	width: 50%; 
	float: left; 
}

.topo-post {
	background-size: cover;
	min-height: 400px;
	background-position: center;
	background-repeat: no-repeat;
}

.flex_post {
	display: flex;
	flex-wrap: wrap;
}

/* ======================================
   Galeria
   ====================================== */
.gallery_apto {
    padding: 0;
    margin: 0;
}

.gallery_apto figure {
    list-style: none;
    display: block;
    position: relative;
    padding: 10px;
    cursor: pointer;
    width: 25%;
    float: left;
}

.gallery_apto figure > div {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    height: 30px;
    background-color: rgba(131, 75, 47, 1);
    color: #fff;
    display: inline-block;
    padding: 5px 15px;
    font-size: 13px;
    letter-spacing: 1px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.gallery_apto.right figure > div {
    right: auto;
    left: 0;
}

.gallery_apto figure:hover > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 22px;
    text-align: center!important;
    background-color: rgba(131, 75, 47, 0.7);
}

.gallery_apto figure:hover > div .show {
    text-align: center!important;
}

.pswp__caption__center,
.pswp__caption__center > div {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

/* ======================================
   Breadcamb
   ====================================== */
.breadcrumb {
	background-color: transparent; 
	text-align: center; 
	margin-bottom: 0;
	padding-top: 35px;
	padding-bottom: 35px;

	li {
		a,
		span {
			color: #fff;
			text-decoration: none;
			font-size: 18px;
		}
	}
}

/* ======================================
   Pagination
   ====================================== */
.custom-pagination ul li {
	a, 
	span {
		border: solid 1px transparent;
		font-size: 18px;
		border-radius: 4px;
		padding: 3px 12px;
		margin: 4px;
		font-weight: bold;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
	}

	&.active span {
		background-color: $blue;
		border-color: $blue;
	}

	&.disabled span {
		border-color: transparent;
	}
}

/* ======================================
   Sweet Alert
   ====================================== */
.sweet-alert p {
	font-weight: 400;
}

/* ======================================
   Footer
   ====================================== */
.footer {
	padding: 2vw 0;
	background-color: $blue-light;
	
	.container {
		display: flex;
		align-items: center;

		div:first-child {
			font-size: 13px;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			font-weight: 500;
		}

		.box_guco {
			min-width: 165px;
			position: relative;
			right: 0;
			top: 10px;
			float: right;

			.dev {
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 1px;
			}

			.guco {
				position: absolute;
				right: 0;
				bottom: 5px;

				a {
					position: relative;
					width: 54px;
					height: 54px;
					display: block;
					text-indent: -10000px;
					background: transparent url('../assets/site/img/guco-small.png') no-repeat scroll left top;
				
					span {
						display: block;
						position: absolute;
						bottom: 35px;
						right: 37px;
						width: 120px;
						height: 40px;
						opacity: 0;
					    z-index: 9;
						background: transparent url('../assets/site/img/logo-guco-web.png') no-repeat scroll left top;
						-webkit-transition: all 0.3s ease-out;
					    transition: all 0.3s ease-out;
					}
				}
			}
		}
	}

	&:hover .container .box_guco .guco a span {
		opacity: 1;
		height: 90px;
	}
}

/* ======================================
   Helpers
   ====================================== */
.icons-home-legend {
	font-size: 15px;
	color: #6aa204;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	font-weight: bold;
}

.img_before {
	position: relative;

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		height: 100%;
		background: transparent url('../assets/site/img/galeria.jpg') no-repeat scroll center center;
		background-size: cover;
	}
}

.d-table {
	display: table;
}

.d-table-cell {
	display: table-cell;
	vertical-align: middle;
}

.d-flex {
	display: flex;
	align-items: center;
}

.flex_wrap {
	display: flex;
	flex-wrap: wrap;
}

.align_center {
	align-items: center;
}

.flex-menu {
	display: flex;
	align-items: center;
	position: relative;
}

.d-inline-block {
	display: inline-block;
}

.m-0-auto {
	margin: 0 auto;
}

.w_full {
	width: 100%;
}

.max_h_80 {
	max-height: 80px;
}

.max_h_100 {
	max-height: 100px;
}

.max_h_120 {
	max-height: 120px;
}

.min_h_150 {
	min-height: 150px;
}

.sl-wrapper .sl-image .sl-caption {
	text-align: center;
	background: rgba(0,0,0,.6);
}

.sl-overlay {
	opacity: 0.8;
}

.t_overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.title_border {
	position: relative;
	padding-bottom: 15px;

	&:after {
		content: "";
	    display: block;
	    position: absolute;
	    bottom: 2px;
	    left: 50%;
	    width: 80px;
	    height: 3px;
	    background: #5483b7;
	    border-radius: 20px;
	    -webkit-transform: translate(-50%, 0);
	    -ms-transform: translate(-50%, 0);
	    transform: translate(-50%, 0);
	}
}

/* ======================================
   Errors
   ====================================== */
.bg_erro {
    background-image: url('../assets/site/img/bg-erro.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    color: #fff;

    h2 {
	    font-size: 50px;
	}

	p {
	    font-size: 26px;
	    margin-bottom: 50px;
	}

	a {
	    display: inline-block;
	    font-size: 20px; 
	    border-radius: 30px; 
	    border: solid 1px #fff;
	    -webkit-transition: all 0.3s ease-out;  
	    transition: all 0.3s ease-out; 
	    padding: 10px 40px;
	    color: #fff;
	    font-weight: 600; 
	    text-decoration: none; 

		&:hover {
		    background-color: #4983bc;
		    border-color: #4983bc; 
		    text-decoration: none; 
		}
	}
}

.table-erro {
    display: table;
    height: 100vh;
}

.table-cell-erro {
    display: table-cell;
    vertical-align: middle;
}

/* ======================================
   Responsive
   ====================================== */
@media (max-width: 991px) {
	.d-flex {
		display: block;
	}
}

@media (max-width: 769px) {
	.maps {
		display: block;
		
		> div {
			width: 100%; 
		
			&:first-child {
				padding-top: 50px; 
				padding-bottom: 50px; 
			}
		}
	}

	.col-50 {
		width: 100%; 
	}
}

@media (max-width: 767px) {
	.t_sm_center {
		text-align: center;
	}

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 22px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }

    .list_post_home > div:last-child {
    	display: none;
    }

    .flex-menu {
    	.logo {
    		min-width: 80px;
    		padding: 10px 0;
    	}

    	.menu {
    		display: none;
    	}

    	.btn_help {
    		width: 100%;
    		text-align: center;
		    padding: 0 10px;
    	}

		.toggle_menu {
			display: block;
		}
	}

	.menu.menu_mobile {
		display: block;
    	width: calc(100% + 30px);
    	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.2);
    	background-color: #fff;
    	margin: 0 -15px;
    	position: relative;
    	z-index: 5;
    	height: 1px;
	   	visibility: hidden;
    	opacity: 0;

    	ul li {
	    	display: block;
	    	width: 100%;

	    	a {
	    		padding: 15px 5px;
	    	}
	    }

	    &.active {
	    	height: 306px;
	    	opacity: 1;
    	    visibility: visible;
	    }
	}

    .img_before:after {
    	display: none;
    }
}

@media (min-width: 768px) {
	.container {
	    width: 100%;
	}
}

@media(max-width: 600px) {
	.breadcrumb {
	    padding-top: 10px;
	    padding-bottom: 10px;

	    li {
	    	a, 
	    	span {
	    		font-size: 16px;
	    	}
	    }
	}

	.flex_post .col-xs-6 {
		width: 100%;
	}

	.contacts-blocks {
		display: block; 
		
		.block {
			width: 100%;
			padding: 30px 5px;
			border: solid 2px #fff;
		}
	}

	.footer .container {
		display: block;

		div:first-child {
			text-align: center;
		}
	}

    .bg_erro {
    	h2 {
	        font-size: 30px;
	    }

	    p {
	        font-size: 20px;
	        margin-bottom: 10px;
	    }

	    a {
	        font-size: 18px; 
	    }
   	}
}

@media(max-width: 520px) {
    .list_post_home > div {
    	width: 100%;
    }
}

@media(max-width: 420px) {
	.block_xs {
		display: block;
	}
}